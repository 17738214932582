.icn {
    display: inline-block;
    background: transparent none no-repeat scroll center center;
    background-size: cover;
    position: relative;

    &::before, &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &::after {
        opacity: 0;
    }

    &:hover, &.active {
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
}

.icn-back-arrow {
    width: 12px;
    height: 20px;

    &::before {
        background-image: url("../images/icn-back-arrow.svg");
    }
    &::after {
        background-image: url("../images/icn-back-arrow-hover.svg");
    }
}

.icn-lock {
    width: 14px;
    height: 16px;

    &::before {
        background-image: url("../images/icn-lock.svg");
    }
    &::after {
        background-image: url("../images/icn-lock.svg");
    }
}

.icn-eye {
    width: 22px;
    height: 16px;

    &::before {
        background-image: url("../images/icn-eye.svg");
    }
    &::after {
        background-image: url("../images/icn-eye-hover.svg");
    }
}

.icn-edit {
    width: 15px;
    height: 15px;

    &::before {
        background-image: url("../images/icn-edit.svg");
    }
    &::after {
        background-image: url("../images/icn-edit-hover.svg");
    }
}

.icn-thumb {
    width: 41px;
    height: 41px;
    background-image: url("../images/icn-thumb.jpg");
}

.icn-bad {
    width: 14px;
    height: 12px;
    background-image: url("../images/icn-bad.svg");
}

.icn-bath {
    width: 14px;
    height: 12px;
    background-image: url("../images/icn-bath.svg");
}

.icn-feet {
    width: 14px;
    height: 14px;
    background-image: url("../images/icn-feet.svg");
}

.icn-person {
    width: 29px;
    height: 33px;
    background-image: url("../images/icn-person.svg");
}

.icn-pin {
    width: 165px;
    height: 202px;
    background-image: url("../images/icn-pin.svg");
}

.icn-error-pin {
    width: 165px;
    height: 202px;
    background-image: url("../images/icn-error-pin.svg");
}

.icn-phone {
    width: 17px;
    height: 18px;
    background-image: url("../images/icn-phone.svg");
}

.icn-home {
    width: 237px;
    height: 151px;
    background-image: url("../images/icn-home.svg");
}

.icn-calander {

    &::before {
        width: 24px;
        height: 23px;    
        background-image: url("../images/icn-calendar.svg");
    }
    &::after {
        width: 24px;
        height: 23px;
        background-image: url("../images/icn-calendar.svg");
    }
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev-button:hover {
    background-image: url("../images/icn-arrow-prev.svg") !important;    
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__next-button:hover {
    background-image: url("../images/icn-arrow-next.svg") !important;    
}

.icn-instant {
    width: 10px;
    height: 14px;
    background-image: url("../images/icn-instant.svg");
}