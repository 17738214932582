$xs: 0;
$sm: 490px;
$md: 767px;
$lg: 1024px;
$xl: 1199px;

$font: 'Roboto';

$primary: #FB5700;
$dark-primary: #CB0303;
$secondary: #1B325F;
$success: #168014;
$red:     #CB0303;
$blue: #1F57E9;
$dark-blue: #1B325F;

$body-font-size: 16px;
$body-line-height: 25px;
$body-color: #828282;

$p-font-size: 16px;
$p-line-height: 25px;

$small-font-size: 12px;
$small-line-height: 16px;

$h1-font-size: 20px;
$h2-font-size: 16px;

$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;

$h1-line-height: 24px;
$h2-line-height: 24px;

$h3-line-height: 36px;
$h4-line-height: 28px;
$h5-line-height: 24px;
$h6-line-height: 20px;
