/*--- Font-Family ---*/
@mixin font-face($font-family, $url, $font-weight, $font-style) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('../fonts/#{$url}.eot');
		src: url('../fonts/#{$url}.eot?#iefix') format('embedded-opentype'),
		     url('../fonts/#{$url}.woff') format('woff'),
		     url('../fonts/#{$url}.ttf') format('truetype'),
		     url('../fonts/#{$url}.svg') format('svg');
		font-weight: #{$font-weight};
		font-style: #{$font-style};
	}
}

/*--- Webkit ---*/
@mixin prefix($property, $parameters) {
	@each $prefix in -webkit-, -moz-, -ms-, -o-, "" {
		#{$prefix}#{$property}: $parameters;
	}
}

/*--- Media Query ---*/
@mixin responsive($media) {
	@if $media == xl {
		@media only screen and (max-width: $xl) { @content; }
	}

	@else if $media == lg {
		@media only screen and (max-width: $lg) { @content; }
	}

	@else if $media == md {
		@media only screen and (max-width: $md) { @content; }
	}

	@else if $media == sm {
		@media only screen and (max-width: $sm) { @content; }
	}
	
	@else if $media == xs {
		@media only screen and (max-width: $xs) { @content; }
	}
}